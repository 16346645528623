<template>
  <div class="page">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :postData="postData"
      methods="post"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="add()"></v-button>
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="communityList"
          v-model="postData.belongCommunity"
          label="所属社区："
        />
        <v-select
          clearable
          :options="buildingList"
          v-model="postData.belongBuilding"
          label="所属楼宇："
        />
        <v-input
          label="姓名："
          v-model="postData.visitingName"
          placeholder="请输入联系人姓名"
        />
        <v-input
          label="手机号："
          v-model="postData.visitingMobile"
          placeholder="请输入联系人电话号码"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="edit(scope.row)" />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      :title="dialogTitle"
      v-model="dialogVisible"
      width="50%"
      sureTxt="保存"
      @confirm="submit"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="所属社区：">
          <v-select
            clearable
            :options="communityList"
            v-model="form.belongCommunity"
          />
        </el-form-item>
        <el-form-item label="所属楼宇：">
          <v-select
            clearable
            :options="buildingList"
            v-model="form.belongBuilding"
            :multiple="true"
          />
        </el-form-item>
        <el-form-item label="姓名：">
          <v-input v-model="form.visitingName" placeholder="请输入联系人姓名" />
        </el-form-item>
        <el-form-item label="手机号：">
          <v-input
            v-model="form.visitingMobile"
            placeholder="请输入联系人电话号码"
          />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  addOrUpVisPerInfoUrl,
  deleteVisPerInfoUrl,
  getVisPerInfoUrl,
  importUrl,
  getSearchWordsUrl,
  updateBusinessInfoUrl,
  deleteBusinessIncomeUrl,
  getBusinessInfosUrl,
  deleteBusinessInfoUrl,
  getBusinessIncomeUrl,
  exportBusinessInfoUrl,
  exportBusinessIncomeUrl,
  exportBusinessRevenueUrl,
  deleteBusinessRevenueUrl,
  getBusinessRevenueUrl,
  sendSmsUrl,
  getBuildingInfoUrl,
  saveLastWordsUrl,
  getLastWordsUrl,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
export default {
  name: "visitors",
  data() {
    return {
      form: {
        id: null,
        visitingName: null,
        visitingMobile: null,
        belongCommunity: null,
        belongBuilding: [],
      },

      dialogVisible: false,
      dialogTitle: null,

      tableUrl: getVisPerInfoUrl,
      postData: {
        belongCommunity: "明珠社区",
        belongBuilding: null,
        visitingName: null,
        visitingMobile: null,
      },
      headers: [
        {
          prop: "visitingName",
          label: "姓名",
        },
        {
          prop: "visitingMobile",
          label: "手机号",
        },
        {
          prop: "belongCommunity",
          label: "所属社区",
        },
        {
          prop: "belongBuilding",
          label: "所属楼宇",
        },
      ],

      communityList: [
        {
          value: "明珠社区",
          label: "明珠社区",
        },
      ],
      buildingList: [],
    };
  },
  created() {
    this.getBuildingList();
  },
  methods: {
    submit() {
      if (!this.form.belongCommunity || this.form.belongCommunity.length == 0) {
        this.$message.error("请选择所属社区！");
        return;
      }
      if (this.form.belongBuilding.length == 0) {
        this.$message.error("请选择所属楼宇！");
        return;
      }
      if (!this.form.visitingName || this.form.visitingName.length == 0) {
        this.$message.error("请输入联系人姓名！");
        return;
      }
      if (!this.form.visitingMobile || this.form.visitingMobile.length == 0) {
        this.$message.error("请输入联系人电话号码！");
        return;
      }
      if (this.dialogTitle == "编辑") {
        let params = {
          id: this.form.id,
          visitingName: this.form.visitingName,
          visitingMobile: this.form.visitingMobile,
          belongCommunity: this.form.belongCommunity,
          belongBuilding: this.form.belongBuilding.join(","),
        };
        this.$axios
          .post(`${addOrUpVisPerInfoUrl}`, params, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            if (res.code == 200) {
              this.dialogVisible = false;
              Message.success(res.msg);
              this.$refs.list.search();
            }
          });
      } else if (this.dialogTitle == "新增") {
        let params = {
          visitingName: this.form.visitingName,
          visitingMobile: this.form.visitingMobile,
          belongCommunity: this.form.belongCommunity,
          belongBuilding: this.form.belongBuilding.join(","),
        };
        this.$axios
          .post(`${addOrUpVisPerInfoUrl}`, params, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            if (res.code == 200) {
              this.dialogVisible = false;
              Message.success(res.msg);
              this.$refs.list.search();
            }
          });
      }
    },
    add() {
      this.form.id = null;
      this.form.visitingName = null;
      this.form.visitingMobile = null;
      this.form.belongCommunity = "明珠社区";
      this.form.belongBuilding = [];
      this.dialogTitle = "新增";
      this.dialogVisible = true;
    },
    edit(item) {
      this.form.id = item.id;
      this.form.visitingName = item.visitingName;
      this.form.visitingMobile = item.visitingMobile;
      this.form.belongCommunity = item.belongCommunity;
      this.form.belongBuilding = item.belongBuilding.split(",");
      this.dialogTitle = "编辑";
      this.dialogVisible = true;
    },
    remove(item) {
      MessageBox.confirm("是否删除", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: item.id,
          };
          this.$axios
            .post(`${deleteVisPerInfoUrl}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                Message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    // 获取楼宇列表
    getBuildingList() {
      this.$axios.get(`${getBuildingInfoUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.value = item.buildingName;
            item.label = item.buildingName;
          });
          this.buildingList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  height: 100%;
  box-sizing: border-box;
}
</style>
